import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Subscribe from "./Subscribe";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Title text="Would you like to get new posts directly to your inbox?" mdxType="Title" />
    <Subscribe mdxType="Subscribe" />
    <p>{`Also you can follow me on `}<a rel="me" href="https://cloud-native.social/@misiekofski">{`Mastodon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      